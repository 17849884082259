<template>
    <form-control-group>
        <form-control-group-row>
            <form-text-input
                v-model="name"
                :error-message="nameErrorMessage"
                required
                :disabled="loading"
                :label="$t('payrollSettings.holidays.fields.name')"
            />

            <form-date-picker
                :label="$t('payrollSettings.holidays.fields.date')"
                single
                v-model="date"
                :error-message="errorDateMessage"
                :disabled="loading"
            />
        </form-control-group-row>

        <form-control-group-row>
            <form-textarea
                v-model="details"
                :label="$t('payrollSettings.holidays.fields.details')"
                :error-message="detailsErrorMessage"
                :disabled="loading"
            />
        </form-control-group-row>
    </form-control-group>
</template>
<script setup>
defineProps({
    loading: Boolean,
})

const { value: name, errorMessage: nameErrorMessage } = useField('name')
const { value: details, errorMessage: detailsErrorMessage } =
    useField('details')
const { value: date, errorMessage: errorDateMessage } = useField('date')
</script>
