import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'employees.payroll-settings',
        path: '/employees/payroll-settings',
        redirect: {
            name: 'employees.payroll-settings.organization',
        },
        component: () => import('./pages/employees-payroll-setting.vue'),
        children: [
            {
                name: 'employees.payroll-settings.organization',
                path: 'organization',
                component: () =>
                    import(
                        './pages/employees-payroll-setting-organization.vue'
                    ),
            },
            {
                name: 'employees.payroll-settings.tax-department',
                path: 'tax-department',
                component: () =>
                    import(
                        './pages/employees-payroll-setting-tax-department.vue'
                    ),
            },
            {
                name: 'employees.payroll-settings.holiday',
                path: 'holiday',
                component: () =>
                    import('./pages/employees-payroll-setting-holiday.vue'),
                redirect: {
                    name: 'employees.payroll-settings.holiday.list',
                },
                children: [
                    {
                        name: 'employees.payroll-settings.holiday.list',
                        path: 'list',
                        component: () =>
                            import(
                                './pages/employees-payroll-setting-holiday-list.vue'
                            ),
                    },
                    {
                        name: 'employees.payroll-settings.holiday.create',
                        path: 'create',
                        component: () =>
                            import(
                                './pages/employees-payroll-setting-holiday-create.vue'
                            ),
                    },
                    {
                        name: 'employees.payroll-settings.holiday.edit',
                        path: 'edit/:id',
                        component: () =>
                            import(
                                './pages/employees-payroll-setting-holiday-edit.vue'
                            ),
                    },
                ],
            },
            {
                name: 'employees.payroll-settings.pay-item',
                path: 'pay-item',
                component: () =>
                    import('./pages/employees-payroll-setting-pay-item.vue'),
            },
            {
                name: 'employees.payroll-settings.opening-balance',
                path: 'opening-balance',
                component: () =>
                    import(
                        './pages/employees-payroll-setting-opening-balance.vue'
                    ),
            },
        ],
    },
]

export default {
    routes,
    meta: { layout: 'dashboard-layout', guard: GUARD.TENANT },
}
