import { isActive } from '@tenant/core/feature'

const navigation = [
    ...(isActive('FEATURE_FLAG_1630')
        ? [
              {
                  id: 'sas-employees-payroll-settings',
                  path: 'employees.payroll-settings',
                  label: 'sidebar.employees.payrollSettings',
                  parent: 'sas-payroll',
                  position: 50,
              },
          ]
        : []),
]

export default navigation
