export default {
    payrollSettings: {
        title: 'Payroll Settings',
        sidebar: {
            organization: 'Organization Profile',
            taxDepartment: 'Tax Department',
            holidays: 'Holidays',
            payItems: 'Pay Items',
            openingBalances: 'Opening Balances',
        },
        organization: {
            form: {
                bankAccount: {
                    label: 'Bank Account',
                },
                payeLiabilityAccount: {
                    label: 'PAYE Liability Account',
                },
                wagesExpenseAccount: {
                    label: 'Wages Expense Account',
                },
                wagesPayableAccount: {
                    label: 'Wages Payable Account',
                },
                ghsLiabilityAccount: {
                    label: 'GHS Liability Account',
                },
                employeeGhsAccount: {
                    label: 'Employee GHS Account',
                },
                benefitEmployeeContribution: {
                    label: 'Benefit Employee Contribution',
                },
            },
        },
        holidays: {
            fields: {
                name: 'Name',
                date: 'Date',
                details: 'Details',
            },
            buttons: {
                add: 'Add Holiday',
            },
        },
        taxDepartment: {
            form: {
                employeePayeReference: {
                    label: 'Employee PAYE Reference',
                },
                corporationTIN: {
                    label: 'Corporation TIN',
                },
                vatNumber: {
                    label: 'VAT Number',
                },
            },
        },
    },
}
