<template>
    <base-heading
        class="px-6 py-4 !pb-2.5"
        :title="$t('payrollSettings.sidebar.holidays')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="onCancel"
                    :loading="loadingGet || loadingSave"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button
                    variant="primary"
                    :loading="loadingGet || loadingSave"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-heading>

    <div class="p-6">
        <employees-payroll-setting-holiday-form
            :loading="loadingGet || loadingSave"
        />
    </div>
</template>

<script setup>
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const emitter = useEmitter()

const { setValues, setErrors, handleSubmit } = useForm()

const { successNotify } = useNotification()

const { useApiGet, useApiSave } = useApiFactory('payroll/setting-holidays')
const { loading: loadingGet, execute: executeGet } = useApiGet()
const { loading: loadingSave, execute: executeSave } = useApiSave()

useHead({
    title: t('payrollSettings.sidebar.holidays'),
})

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const data = await executeGet(route.params.id)

        setValues(data)
    } finally {
        emitter.emit('set-loading', false)
    }
})
const onCancel = () => {
    router.push({ name: 'employees.payroll-settings.holiday.list' })
}

const onSave = handleSubmit(async (values) => {
    try {
        const holiday = await executeSave(values, route.params.id)

        await router.push({
            name: 'employees.payroll-settings.holiday.edit',
            params: { id: holiday.id },
        })

        successNotify({
            title: t('general.success'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
})
</script>
