<template>
    <base-heading
        class="px-6 py-4 !pb-2.5"
        :title="$t('payrollSettings.sidebar.holidays')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button
                    variant="primary"
                    :loading="loading"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-heading>

    <div class="p-6">
        <employees-payroll-setting-holiday-form :loading="loading" />
    </div>
</template>

<script setup>
const { t } = useI18n()
const router = useRouter()

const { setErrors, handleSubmit } = useForm()

const { successNotify } = useNotification()

const { loading, execute } = useApiFactory(
    'payroll/setting-holidays'
).useApiCreate()

useHead({
    title: t('payrollSettings.sidebar.holidays'),
})

const onCancel = () => {
    router.push({ name: 'employees.payroll-settings.holiday.list' })
}

const onSave = handleSubmit(async (values) => {
    try {
        const holiday = await execute(values)

        await router.push({
            name: 'employees.payroll-settings.holiday.edit',
            params: { id: holiday.id },
        })

        successNotify({
            title: t('general.success'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
})
</script>
