<template>
    <base-heading
        class="px-6 py-4 !pb-2.5"
        :title="$t('payrollSettings.sidebar.organization')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="primary"
                    :loading="loadingSave"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-heading>

    <div class="p-6">
        <form-control-group>
            <form-control-group-row>
                <form-single-select
                    v-model="bankAccountId"
                    :error-message="bankAccountIdErrorMessage"
                    :options="paymentAccounts"
                    :label="
                        $t(
                            'payrollSettings.organization.form.bankAccount.label'
                        )
                    "
                    :disabled="
                        loadingFetch ||
                        loadingSave ||
                        loadingFetchPaymentAccount
                    "
                />

                <form-detail-chart-of-account
                    v-model="payeLiabilityAccountId"
                    :error-message="payeLiabilityAccountIdErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.organization.form.payeLiabilityAccount.label'
                        )
                    "
                />

                <form-detail-chart-of-account
                    v-model="wagesExpenseAccountId"
                    :error-message="wagesExpenseAccountIdErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.organization.form.wagesExpenseAccount.label'
                        )
                    "
                />
            </form-control-group-row>

            <form-control-group-row>
                <form-detail-chart-of-account
                    v-model="wagesPayableAccountId"
                    :error-message="wagesPayableAccountIdErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.organization.form.wagesPayableAccount.label'
                        )
                    "
                />
                <form-detail-chart-of-account
                    v-model="ghsLiabilityAccountId"
                    :error-message="ghsLiabilityAccountIdErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.organization.form.ghsLiabilityAccount.label'
                        )
                    "
                />
                <form-detail-chart-of-account
                    v-model="employeeGhsAccountId"
                    :error-message="employeeGhsAccountIdErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.organization.form.employeeGhsAccount.label'
                        )
                    "
                />
            </form-control-group-row>

            <form-control-group-row>
                <form-detail-chart-of-account
                    v-model="benefitEmployeeContributionAccountId"
                    :error-message="
                        benefitEmployeeContributionAccountIdErrorMessage
                    "
                    :label="
                        $t(
                            'payrollSettings.organization.form.benefitEmployeeContribution.label'
                        )
                    "
                />
            </form-control-group-row>
        </form-control-group>
    </div>
</template>
<script setup>
const emitter = useEmitter()
const { t } = useI18n()
const { setErrors, handleSubmit, setValues } = useForm()

const { value: bankAccountId, errorMessage: bankAccountIdErrorMessage } =
    useField('payment_account_id')

const {
    value: payeLiabilityAccountId,
    errorMessage: payeLiabilityAccountIdErrorMessage,
} = useField('paye_account_id')

const {
    value: wagesExpenseAccountId,
    errorMessage: wagesExpenseAccountIdErrorMessage,
} = useField('wage_expense_account_id')

const {
    value: wagesPayableAccountId,
    errorMessage: wagesPayableAccountIdErrorMessage,
} = useField('wage_payable_account_id')

const {
    value: ghsLiabilityAccountId,
    errorMessage: ghsLiabilityAccountIdErrorMessage,
} = useField('ghs_account_id')

const {
    value: employeeGhsAccountId,
    errorMessage: employeeGhsAccountIdErrorMessage,
} = useField('employee_ghs_account_id')

const {
    value: benefitEmployeeContributionAccountId,
    errorMessage: benefitEmployeeContributionAccountIdErrorMessage,
} = useField('benefit_empl_account_id')

const {
    execute: executeListPaymentAccounts,
    loading: loadingFetchPaymentAccount,
} = useApi('api/payment-accounts', 'GET')

const { execute: fetchSetting, loading: loadingFetch } = useApi(
    'api/payroll/setting-organization',
    'GET'
)

const { execute: updateSetting, loading: loadingSave } = useApi(
    'api/payroll/setting-organization',
    'POST'
)

const { successNotify } = useNotification()

const paymentAccounts = ref([])
const getAccountList = () => {
    return executeListPaymentAccounts().then((response) => {
        response.data.map((paymentAcc) =>
            paymentAccounts.value.push({
                value: paymentAcc.id,
                label: paymentAcc.name,
            })
        )
    })
}

onMounted(() => {
    emitter.emit('set-loading', true)

    getAccountList()

    fetchSetting().then((response) => {
        setValues(response)
    })

    Promise.all([getAccountList(), fetchSetting()]).then(() => {
        emitter.emit('set-loading', false)
    })
})

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        await updateSetting({ data: values })

        successNotify({
            title: t('general.success'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
})
</script>
