<template>
    <base-heading
        class="px-6 py-4 !pb-2.5"
        :title="$t('payrollSettings.sidebar.holidays')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="primary"
                    :loading="loadingSearch"
                    @click="onCreate"
                >
                    {{ $t('payrollSettings.holidays.buttons.add') }}
                </base-button>
            </div>
        </template>
    </base-heading>

    <data-grid
        table-borderless
        :data-source="entities.data"
        :columns="columns"
        :loading="loadingSearch"
        identify-field="id"
        @row-click="redirectToDetail"
    >
        <template #column-date="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ formatDate(item.date) }}
            </div>
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
    </data-grid>
</template>
<script setup>
const { t } = useI18n()
const router = useRouter()

const { entities, useApiSearch } = useApiFactory('payroll/setting-holidays')
const { loading: loadingSearch } = useApiSearch()

useHead({
    title: t('payrollSettings.sidebar.holidays'),
})

const columns = [
    {
        property: 'name',
        label: t('payrollSettings.holidays.fields.name'),
    },
    {
        property: 'date',
        label: t('payrollSettings.holidays.fields.date'),
    },
]

const redirectToDetail = (id) => {
    router.push({
        name: 'employees.payroll-settings.holiday.edit',
        params: { id },
    })
}

const onCreate = () => {
    router.push({ name: 'employees.payroll-settings.holiday.create' })
}

const formatDate = (date) => {
    const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    const currentDate = new Date(date)
    const dayOfWeek = weekdays[currentDate.getDay()]
    const day = String(currentDate.getDate()).padStart(2, '0')
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = currentDate.getFullYear()

    return `${dayOfWeek} ${day}.${month}.${year}`
}
</script>
