<template>
    <div
        class="flex items-center justify-between border-b border-gray-200 px-6 py-4"
    >
        <h5 class="text-lg font-medium leading-9 text-gray-900">
            {{ $t('payrollSettings.title') }}
        </h5>
    </div>

    <div
        class="flex flex-col gap-2 overflow-auto px-6 py-5 text-md font-medium text-gray-700"
    >
        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.payroll-settings.organization'
                ),
            }"
            @click="onRedirectRoute('employees.payroll-settings.organization')"
        >
            <base-icon
                name="line-icons:general:home-03"
                variant="inherit"
                size="lg"
            />

            {{ $t('payrollSettings.sidebar.organization') }}
        </div>

        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.payroll-settings.tax-department'
                ),
            }"
            @click="
                onRedirectRoute('employees.payroll-settings.tax-department')
            "
        >
            <base-icon
                name="line-icons:general:percent-03"
                variant="inherit"
                size="lg"
            />

            {{ $t('payrollSettings.sidebar.taxDepartment') }}
        </div>

        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.payroll-settings.holiday'
                ),
            }"
            @click="onRedirectRoute('employees.payroll-settings.holiday')"
        >
            <base-icon
                name="line-icons:weather:umbrella-03"
                variant="inherit"
                size="lg"
            />

            {{ $t('payrollSettings.sidebar.holidays') }}
        </div>

        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.payroll-settings.pay-item'
                ),
            }"
            @click="onRedirectRoute('employees.payroll-settings.pay-item')"
        >
            <base-icon
                name="line-icons:shapes:cube-03"
                variant="inherit"
                size="lg"
            />

            {{ $t('payrollSettings.sidebar.payItems') }}
        </div>
        <div
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            :class="{
                'bg-primary-600 text-white': isRoute(
                    'employees.payroll-settings.opening-balance'
                ),
            }"
            @click="
                onRedirectRoute('employees.payroll-settings.opening-balance')
            "
        >
            <base-icon
                name="line-icons:editor:pencil-01"
                variant="inherit"
                size="lg"
            />

            {{ $t('payrollSettings.sidebar.openingBalances') }}
        </div>
    </div>
</template>
<script setup>
const router = useRouter()
const route = useRoute()

const onRedirectRoute = (routeName) => {
    router.push({
        name: routeName,
    })
}

const isRoute = (routeName) => {
    return route.matched.some((i) => i.name === routeName)
}
</script>
