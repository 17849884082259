<template>
    <base-heading
        class="px-6 py-4 !pb-2.5"
        :title="$t('payrollSettings.sidebar.taxDepartment')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="primary"
                    :loading="loadingSave"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-heading>

    <div class="p-6">
        <form-control-group>
            <form-control-group-row>
                <form-text-input
                    v-model="employeePayeReference"
                    :error-message="employeePayeReferenceErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.taxDepartment.form.employeePayeReference.label'
                        )
                    "
                    :disabled="loadingSave || loadingFetch"
                />
                <form-text-input
                    v-model="corporationTIN"
                    :error-message="corporationTINErrorMessage"
                    :label="
                        $t(
                            'payrollSettings.taxDepartment.form.corporationTIN.label'
                        )
                    "
                    :disabled="loadingSave || loadingFetch"
                />
                <form-text-input
                    v-model="vatNumber"
                    :error-message="vatNumberErrorMessage"
                    :label="
                        $t('payrollSettings.taxDepartment.form.vatNumber.label')
                    "
                    :disabled="loadingSave || loadingFetch"
                />
            </form-control-group-row>
        </form-control-group>
    </div>
</template>
<script setup>
const emitter = useEmitter()
const { t } = useI18n()
const { setErrors, handleSubmit, setValues } = useForm()

const {
    value: employeePayeReference,
    errorMessage: employeePayeReferenceErrorMessage,
} = useField('employee_paye_reference')
const { value: corporationTIN, errorMessage: corporationTINErrorMessage } =
    useField('corporation_tin')
const { value: vatNumber, errorMessage: vatNumberErrorMessage } =
    useField('vat_number')

const { execute: fetchSetting, loading: loadingFetch } = useApi(
    'api/payroll/setting-tax-department',
    'GET'
)

const { execute: updateSetting, loading: loadingSave } = useApi(
    'api/payroll/setting-tax-department',
    'POST'
)

const { successNotify } = useNotification()

onMounted(() => {
    emitter.emit('set-loading', true)

    fetchSetting().then((response) => {
        setValues(response)

        emitter.emit('set-loading', false)
    })
})

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        await updateSetting({ data: values })

        successNotify({
            title: t('general.success'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
})
</script>
